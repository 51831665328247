.rs__button {
  width: 44px;
  height: 44px;
  position: relative;
  background: none;
  cursor: pointer;
  overflow: visible;
  -webkit-appearance: none;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
  opacity: 0.75;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs__button:focus, .rs__button:hover {
  opacity: 1;
}

.rs__button:active {
  outline: none;
  opacity: 0.9;
}

.rs__button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.rs__ui--over-close .rs__button--close {
  opacity: 1;
}

.rs__button, .rs__button--arrow--left:before, .rs__button--arrow--right:before {
  background: url(assets/default-skin.png) 0 0 no-repeat;
  background-size: 264px 88px;
  width: 44px;
  height: 44px;
}


.rs__button--close {
  background-position: 0 -44px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .rs__button, .rs__button--arrow--left:before, .rs__button--arrow--right:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjY0IDg4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNjQgODgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZT4uc3R5bGUwe3N0cm9rZToJIzAwMDAwMDtzdHJva2Utd2lkdGg6CTEuNTtmaWxsOglub25lO308L3N0eWxlPjx0aXRsZT5kZWZhdWx0LXNraW4gMjwvdGl0bGU+PGc+PGc+PHBhdGggZD0iTTY3IDU5LjV2My44Yy02LjMgMC44LTkuMiA1LjgtMTAgOS43YzIuMi0yLjggNS42LTUuMSAxMC01LjF2My42bDYtNS45TDY3IDU5LjV6Ii8+PGc+PHBhdGggZD0iTTEzIDI5di01aDJ2M2gzdjJIMTN6IE0xMyAxNWg1djJoLTN2M2gtMlYxNXogTTMxIDE1djVoLTJ2LTNoLTN2LTJIMzF6IE0zMSAyOWgtNXYtMmgzdi0zaDJWMjl6Ii8+PC9nPjxnPjxwYXRoIGQ9Ik02MiAyNHY1aC0ydi0zaC0zdi0ySDYyeiBNNjIgMjBoLTV2LTJoM3YtM2gyVjIweiBNNzAgMjB2LTVoMnYzaDN2Mkg3MHogTTcwIDI0aDV2MmgtM3YzaC0yVjI0eiIvPjwvZz48cGF0aCBkPSJNMjAuNiA2NmwtNS43LTUuN2wxLjQtMS40bDUuNyA1LjdsNS43LTUuN2wxLjQgMS40TDIzLjQgNjZsNS43IDUuN2wtMS40IDEuNEwyMiA2Ny40bC01LjcgNS43bC0xLjQtMS40TDIwLjYgNjZ6Ii8+PHBhdGggZD0iTTExMS44IDY1bC0xLjgtMS41bDMtMy41aC0xMHYtMmgxMGwtMy0zLjVsMS44LTEuNWw1LjIgNkwxMTEuOCA2NXoiLz48cGF0aCBkPSJNMTUyLjIgNjVsMS44LTEuNWwtMy0zLjVoMTB2LTJoLTEwbDMtMy41bC0xLjgtMS41bC01LjIgNkwxNTIuMiA2NXoiLz48Zz48cGF0aCBkPSJNMTYxIDI4LjVsLTMuMi0zLjJsLTEuNCAxLjRsMy4yIDMuMkwxNjEgMjguNXoiLz48cGF0aCBkPSJNMTUyLjUgMjdjMyAwIDUuNS0yLjUgNS41LTUuNXMtMi41LTUuNS01LjUtNS41IHMtNS41IDIuNS01LjUgNS41UzE0OS41IDI3IDE1Mi41IDI3eiIgY2xhc3M9InN0eWxlMCIvPjxwYXRoIGQ9Ik0xNTAgMjFoNXYxaC01VjIxeiIvPjwvZz48Zz48cGF0aCBkPSJNMTE3IDI4LjVsLTEuNCAxLjRsLTMuMi0zLjJsMS40LTEuNEwxMTcgMjguNXoiLz48cGF0aCBkPSJNMTA4LjUgMjdjMyAwIDUuNS0yLjUgNS41LTUuNXMtMi41LTUuNS01LjUtNS41cy01LjUgMi41LTUuNSA1LjUgUzEwNS41IDI3IDEwOC41IDI3eiIgY2xhc3M9InN0eWxlMCIvPjxwYXRoIGQ9Ik0xMDYgMjFoNXYxaC01VjIxeiIvPjxwYXRoIGQ9Ik0xMDkgMTlsLTAuMSA1bC0xIDBsMC4xLTVMMTA5IDE5eiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==);
  }
}