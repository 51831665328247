.black-dot {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: black;
  margin: 10px;
  margin-right: 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.black-dot.mini {
  display: none;
}