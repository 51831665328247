.thumbnail-excerpt {
  position: absolute;
  top: 100%;
  margin-top: var(--halfspace);
  font-size: var(--fontsize);
  line-height: var(--lineheight);
  max-width: var(--textwidth);
  height: 4em;
  overflow: hidden;
}

.thumbnail-excerpt .title {
  font-family: var(--bold);
}

.thumbnail-excerpt::before {
  content: " ";
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    var(--bgcolor)
  );
  width: 70%;
  height: 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}
