.project-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: white;
  color: purple;
  margin-bottom: var(--space);
}

.project-container.overlayd {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100vh;
  opacity: 0.01;
  transition: opacity 0.5s ease-in;
  padding: 0 8px;


}

.outer-content-container {
  position: relative;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--space4) var(--space4);
}

.project-container.overlayd.fade-in {
  opacity: 1;
}

.project-container > * {
  color: black;
}

.project-container_off::before {
  content: " ";
  background-image: linear-gradient(rgba(255, 255, 255, 0), currentColor);
  width: 100%;
  height: 40vh;
  position: absolute;
  top: -39.8vh;
  pointer-events: none;
}

.project-container_off::after {
  content: " ";
  background-image: linear-gradient(currentColor, rgba(255, 255, 255, 0));
  width: 100%;
  height: 40vh;
  position: absolute;
  bottom: -39.8vh;
  pointer-events: none;
}
