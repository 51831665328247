:root {
  --maxsitewidth: calc(600px + 40vw);
  /* --bgcolor: #FBB917 */
  --bgcolor: #ffffff;
  --txtcolor: #000000;
  --shadowcolor: #eee;
  --size: 18px;
  --fontsize: 18px;
  --lineheight: 1.3em;
  --fontsizeheader: 1.8em;
  --textwidth: 32em;
  --bread: "Brandon Grotesque", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  --bold: "Brandon Grotesque", "Helvetica Neue Bold", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  --space4: 24px;
  --space: 1rem;
  --halfspace: 8px;
  --thirdspace: 4px;
  --sixthspace: 4px;
}

@media (max-width: 360px) {
  :root {
    --space4: 16px;
    --space: 12px;
    --halfspace: 6px;
    --thirdspace: 4px;
    --sixthspace: 4px;
  }
}

html {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: var(--bread);
  color: var(--txtcolor);
  font-size: var(--size);
  line-height: var(--lineheight);
  -webkit-text-size-adjust: none;
}

body {
  background-color: var(--bgcolor);
  width: 100%;
  padding: 0;
  margin: 0;
}

html,
body {
  overscroll-behavior: contain;
  scroll-behavior: unset;
  text-size-adjust: 100%;
  touch-action: pan-y;
}

.home {
  position: relative;
  max-width: var(--maxsitewidth);
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--space4);
  margin-bottom: 80px;
}

.close-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

a {
  color: var(--txtcolor);
}

a:hover {
  color: #666;
}

.example-enter {
  opacity: 0.01;
  transition: opacity 0.5s ease-in;
}

.example-enter.example-enter-active {
  opacity: 1;
}

.example-leave {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.example-leave.example-leave-active {
  opacity: 0.01 !important;
}

.ratio-container {
  margin: --space;
  position: relative;
}

.ratio-content {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ffffff;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 450ms ease-in;
}

.fade-exit {
  opacity: 1;
  /* transform: scale(1) translateY(0%); */
}
.fade-exit-active {
  opacity: 0.01;
  /* transform: scale(0.9) translateY(50%); */
  transition: opacity 300ms ease-out;
}
