.small-image-container {
  /* box-shadow: $thirdspace $thirdspace 0px 0px $shadowcolor */
  font-size: var(--fontsize);
  max-width: var(--textwidth);
  margin: var(--space) 0;
}

.small-image-content {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #eee;
  cursor: default;
}

.small-image-content img {
  position: absolute;
  top: 0;
  width: 100%;
}
