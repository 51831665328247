.entity {
  position: absolute;
  transition: all 300ms ease;
}

.entity-content {
  width: 100px;
  height: 100px;
  position: absolute;
  left: -50px;
  top: -50px;
  padding: 30px;
  border-radius: 50px;
  box-sizing: border-box;
}

.entity.mini .entity-content {
  width: 60px;
  height: 60px;
  left: -30px;
  top: -30px;
}
