.small-video-container {
  /* box-shadow: $thirdspace $thirdspace 0px 0px $shadowcolor */
  margin: var(--space) 0;
  font-size: var(--fontsize);
  max-width: var(--textwidth);
}

.small-video-content {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #eeeeee;
}
