.video-container {
  /* box-shadow: $thirdspace $thirdspace 0px 0px $shadowcolor */
  margin: var(--space) 0;
}

.video-content {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #eeeeee;
}
