.thumbs-container0 {
  margin: 0;
  margin-bottom: 77vh;
  /* clear: both; */
}

.thumbs-container .width-fixer {
  overflow: visible;

  display: flex;
  flex-wrap: wrap;
}

.thumbs-container .width-fixer::after {
  content: "";
  /* clear: both; */
  /* display: table; */
}
