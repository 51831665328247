header {
  font-size: var(--fontsize);
  padding: var(--space) 0;
}

header .i_am {
  float: left;
  width: 0;
  white-space: nowrap;
}

@media (max-width: 520px) {
  header .i_am {
    min-height: 2.8em;
    width: auto;
    white-space: normal;
  }
}

header .contact {
  float: right;
  max-width: 100%;
}

@media (max-width: 950px) {
  header .contact {
    float: left;
    clear: both;
  }
}

header .contact a {
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap;
}
